import React from 'react';
import { Link } from 'react-router-dom';
import ReactFlow, { Panel, MiniMap } from 'reactflow';
import { Progress } from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { getCompoundEntityRef } from '@backstage/catalog-model';
import { makeStyles, createStyles } from '@material-ui/core';
import SecondaryNode from '../RoadmapNodes/SecondaryNode';
import PrimaryNode from '../RoadmapNodes/PrimaryNode';
import { Roadmap } from '../../api/RoadmapStorageApi';
import { useRawRoadmap } from '../hooks';

import 'reactflow/dist/style.css';

import { TECHDOCS_ROADMAPS_ANNOTATION } from '../../annotations';
import { entityRoadmapEditorRouteRef } from '../../routes';
import { useDocViewerStore, DocViewerState } from './store';
import { DocViewer } from './DocViewer';

const nodeTypes = { primary: PrimaryNode, secondary: SecondaryNode };

const selector = (state: DocViewerState) => ({
  openDrawer: state.openDrawer,
});

const useStyles = makeStyles(() =>
  createStyles({
    flowWrapper: {
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      margin: '0',
      padding: '0',
      boxSizing: 'border-box',
      fontFamily: 'sans-serif',
      '& .react-flow__node': {
        minHeight: '40px',
        minWidth: '150px',
        maxWidth: '250px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderWidth: '2px',
        fontWeight: 700,
      },
      '& .react-flow__handle': {
        visibility: 'hidden',
      },
      '& .react-flow__edge path': {
        strokeWidth: '2',
      },
      '& .react-flow__connectionline path': {
        strokeWidth: '2',
      },
    },
  }),
);

/*
 * RoadmapViewerComponent is the core Roadmap display component, it requires en entity provider
 * meaning it must be use inside an entity page of via the stand alone RoadmapViewerPage
 */
export const RoadmapViewerComponent = () => {
  const classes = useStyles();
  const { entity } = useEntity();
  const entityPath = getCompoundEntityRef(entity);
  const useEditorURL = useRouteRef(entityRoadmapEditorRouteRef);
  const editUrl = useEditorURL({
    namespace: (entity.metadata.namespace ?? 'default').toLocaleLowerCase(),
    kind: entity.kind.toLocaleLowerCase(),
    name: entity.metadata.name.toLocaleLowerCase(),
  });

  // Read the entity annotation to be able to retrieve the roadmap, if the annotation contains docs prefix remove it
  const roadmapPath = entity.metadata.annotations![
    TECHDOCS_ROADMAPS_ANNOTATION
  ].replace('docs/', '');
  const roadmap = useRawRoadmap(roadmapPath, entityPath);

  // doc viewer
  const { openDrawer } = useDocViewerStore(selector);

  if (roadmap.loading) {
    return <Progress />;
  } else if (!entity) {
    return <Alert severity="error">Entity not found</Alert>;
  } else if (roadmap.error) {
    return (
      <Alert severity="error" style={{ whiteSpace: 'pre-line' }}>
        {roadmap.error.message}
      </Alert>
    );
  } else if (roadmap.value === undefined) {
    return (
      <Alert severity="error">
        Failed to Load Roadmap - JSON description is empty
      </Alert>
    );
  }

  const roadmapGraph = roadmap.value.content as Roadmap;

  return (
    <div id="reactFlowWrapper" className={classes.flowWrapper}>
      <DocViewer />
      <ReactFlow
        nodes={roadmapGraph.nodes}
        edges={roadmapGraph.edges}
        nodeTypes={nodeTypes}
        onNodeClick={openDrawer}
        fitView
      >
        <Panel position="top-right">
          <IconButton
            aria-label="Edit"
            title="Go To Editor"
            component={Link}
            to={editUrl}
          >
            <EditIcon />
          </IconButton>
        </Panel>
        <MiniMap />
      </ReactFlow>
    </div>
  );
};
