import {
  configApiRef,
  createApiFactory,
  createPlugin,
  identityApiRef,
  createComponentExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { DefaultPrometheusApi, PrometheusApiRef } from './api';

const prometheusApiFactory = createApiFactory({
  api: PrometheusApiRef,
  deps: {
    configApi: configApiRef,
    identityApi: identityApiRef,
  },
  factory: ({ configApi, identityApi }) =>
    new DefaultPrometheusApi({
      configApi: configApi,
      identityApi: identityApi,
    }),
});

export const prometheusPlugin = createPlugin({
  apis: [prometheusApiFactory],
  id: 'prometheus',
  routes: {
    root: rootRouteRef,
  },
});

export const LineGraph = prometheusPlugin.provide(
  createComponentExtension({
    name: 'LineGraph',
    component: {
      lazy: () => import('./components/Graph').then(m => m.LineGraph),
    },
  }),
);

export const AreaGraph = prometheusPlugin.provide(
  createComponentExtension({
    name: 'AreaGraph',
    component: {
      lazy: () => import('./components/Graph').then(m => m.AreaGraph),
    },
  }),
);

export const AlertsTableWrapper = prometheusPlugin.provide(
  createComponentExtension({
    name: 'AlertsTableWrapper',
    component: {
      lazy: () =>
        import('./components/AlertsTableWrapper').then(
          m => m.AlertsTableWrapper,
        ),
    },
  }),
);
