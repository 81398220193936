import { InfoCard, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import React from 'react';
import {
  LineGraph,
  resultToGraphData,
  useRangeQuery,
} from '@efg/backstage-plugin-prometheus';
import { Grid, Typography } from '@material-ui/core';

export const VitalsUptimeCard = () => {
  const query = 'avg by (team) (faceit_com_animals_team_uptime{team="global"})';
  const end = new Date();
  const start = new Date();
  start.setDate(end.getDate() - 30);
  const step = 48000;

  const { loading, error, value } = useRangeQuery(query, start, end, step);
  const { data, keys, metrics } = resultToGraphData(value?.result || []);

  if (loading) return <Progress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  const uptimeNow =
    data?.length && data[data.length - 1]?.global
      ? Number(data[data.length - 1].global.toFixed(1))
      : 'No global metric available';

  return (
    <InfoCard variant="fullHeight" title="FACEIT">
      <Grid container>
        <Grid
          item
          md={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h6">UPTIME: {uptimeNow}%</Typography>
        </Grid>

        <Grid item xs={12} md={11}>
          <LineGraph data={data} keys={keys} metrics={metrics} unit="percent" />
        </Grid>
      </Grid>
    </InfoCard>
  );
};
