import { InfoCard, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { GroupEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useInstantQuery } from '@efg/backstage-plugin-prometheus';
import { Typography } from '@material-ui/core';

export const UptimeCard = () => {
  const { entity: group } = useEntity<GroupEntity>();
  const query = `min by (team) (last_over_time(faceit_com_animals_team_uptime{team="${group.metadata.name}"}[7d]))`;

  const { loading, error, value } = useInstantQuery(query);

  if (loading) return <Progress />;
  if (error) {
    return (
      <Alert severity="error">
        {error?.message || 'An unknown error occurred'}
      </Alert>
    );
  }

  if (!value?.result || value.result.length === 0) {
    return <Alert severity="warning">No Uptime data available.</Alert>;
  }
  const uptime = Number(value.result[0].value?.value || 0).toFixed(2);

  return (
    <InfoCard
      variant="flex"
      title={
        <Typography style={{ display: 'flex', alignItems: 'center' }}>
          UPTIME
        </Typography>
      }
    >
      <Typography variant="h6">{uptime}%</Typography>
    </InfoCard>
  );
};
