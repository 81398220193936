import { EntityLayout, EntitySwitch } from '@backstage/plugin-catalog';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { isTechDocsAvailable } from '@backstage/plugin-techdocs';
import {
  EntityGroupProfileWithManager,
  EntityMembersListWithManager,
} from '@efg/backstage-plugin-org-with-manager';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import {
  DoraMetricsGroupCard,
  isDoraMetricsAvailable,
} from '@efg/backstage-plugin-dora-metrics';
import {
  CostMetricsGroupCard,
  isCostMetricsAvailable,
} from '@efg/backstage-plugin-cost-metrics';
import { LeaderboardTeamScoreCard } from '@efg/backstage-plugin-leaderboard';
import { FollowUpCard } from '@efg/backstage-plugin-incident-react';
import { SLOTable } from '@efg/backstage-plugin-slo';
import { AlertsTableWrapper } from '@efg/backstage-plugin-prometheus';
import { entityWarningContent } from '../EntityContent/EntityWarningContent';
import { techdocsRoutableContent } from '../EntityContent/TechdocsContent';
import { ReliabilityCard } from './GroupPage/ReliabilityCard';
import { ReliabilityInfoDialog } from './GroupPage/ReliabilityInfoDialog';
import { DowntimeCard } from './GroupPage/DowntimeCard';
import { UptimeCard } from './GroupPage/UptimeCard';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  cardContainer: {
    height: '100%',
  },
  membersSection: {
    marginTop: theme.spacing(1),
  },
  reliabilitySection: {
    marginTop: theme.spacing(1),
  },
}));

export const GroupPage = () => {
  const { entity: groupEntity } = useAsyncEntity();
  const classes = useStyles();

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} className={classes.container}>
          {entityWarningContent}

          {/* First Row: Overview and DORA Metrics */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <EntityGroupProfileWithManager variant="gridItem" />
              </Grid>
              <EntitySwitch>
                <EntitySwitch.Case if={isDoraMetricsAvailable}>
                  <Grid item xs={12} md={6}>
                    <DoraMetricsGroupCard />
                  </Grid>
                </EntitySwitch.Case>
              </EntitySwitch>
            </Grid>
          </Grid>

          {/* Second Row: Three Metrics Cards */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <LeaderboardTeamScoreCard />
              </Grid>
              <Grid item xs={12} md={4}>
                <ReliabilityCard />
              </Grid>
              <EntitySwitch>
                <EntitySwitch.Case if={isCostMetricsAvailable}>
                  <Grid item xs={12} md={4}>
                    <CostMetricsGroupCard />
                  </Grid>
                </EntitySwitch.Case>
              </EntitySwitch>
            </Grid>
          </Grid>

          {/* Members List Section */}
          <Grid item xs={12} className={classes.membersSection}>
            <EntityMembersListWithManager
              memberDisplayTitle="Team Members"
              pageSize={8}
            />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      {techdocsRoutableContent({ if: isTechDocsAvailable })}

      <EntityLayout.Route path="/ownership" title="Ownership">
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <EntityOwnershipCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/reliability" title="Reliability">
        <Grid container spacing={3} className={classes.reliabilitySection}>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12} md={9}>
              <FollowUpCard />
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ReliabilityInfoDialog />
                </Grid>
                <Grid item xs={12}>
                  <DowntimeCard />
                </Grid>
                <Grid item xs={12}>
                  <UptimeCard />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SLOTable
              filter={{ team: groupEntity?.metadata.name }}
              pageSize={10}
              nowrapSLOName
            />
          </Grid>

          <Grid item xs={12}>
            <AlertsTableWrapper />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};
