import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import Alert from '@material-ui/lab/Alert';

import { useRouteRefParams, useApi } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import {
  catalogApiRef,
  EntityProvider,
  EntityRefLink,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Page,
  Header,
  HeaderLabel,
  Progress,
  Content,
} from '@backstage/core-components';

import { makeStyles } from '@material-ui/core';
import { entityRoadmapViewerRouteRef } from '../../routes';
import { TECHDOCS_ROADMAPS_ANNOTATION } from '../../annotations';
import { RoadmapViewerComponent } from './RoadmapViewerComponent';

const useStyles = makeStyles(
  {
    content: {
      padding: 0,
    },
  },
  { name: 'RoadmapEditorPage' },
);

export const RoadmapViewerPageHeader = () => {
  const { entity } = useEntity();

  return (
    <Header
      title="RoadMap Viewer"
      subtitle="Navigate your documentation through an interacive map"
    >
      <HeaderLabel
        label="Entity"
        value={<EntityRefLink entityRef={entity} />}
      />
      <HeaderLabel
        label="Roadmap Path"
        value={entity.metadata.annotations![TECHDOCS_ROADMAPS_ANNOTATION]}
      />
    </Header>
  );
};

/*
 * RoadmapViewerPage load an existing Roadmap and renders it
 */
export const RoadmapViewerPage = () => {
  const classes = useStyles();
  const { kind, name, namespace } = useRouteRefParams(
    entityRoadmapViewerRouteRef,
  );
  const catalogApi = useApi(catalogApiRef);

  // Load the entity from the uri path
  const entity = useAsync(async (): Promise<Entity | undefined> => {
    return await catalogApi.getEntityByRef({ kind, name, namespace });
  }, [kind, name, namespace]);

  if (entity.loading) {
    return <Progress />;
  } else if (entity.error) {
    return <Alert severity="error">{entity.error.message}</Alert>;
  } else if (entity.value === undefined) {
    return (
      <Alert severity="error">
        Entity {kind}:{namespace}:{name} does not exist
      </Alert>
    );
  }

  return (
    <EntityProvider entity={entity.value}>
      <Page themeId="documentation">
        <RoadmapViewerPageHeader />
        <Content className={classes.content}>
          <RoadmapViewerComponent />
        </Content>
      </Page>
    </EntityProvider>
  );
};
