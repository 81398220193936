import useAsync, { AsyncState } from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { PrometheusQueryDate, QueryResult } from 'prometheus-query';
import { ExtendedAlert } from './types';
import { PrometheusApiRef } from './api';

export function useInstantQuery(
  query: string,
  time?: PrometheusQueryDate,
  timeout?: string,
): AsyncState<QueryResult> {
  const promApi = useApi(PrometheusApiRef);

  return useAsync(async (): Promise<QueryResult> => {
    return await promApi.instantQuery(query, time, timeout);
  });
}

export function useRangeQuery(
  query: string,
  start: PrometheusQueryDate,
  end: PrometheusQueryDate,
  step: string | number,
  timeout?: string,
): AsyncState<QueryResult> {
  const promApi = useApi(PrometheusApiRef);

  return useAsync(async (): Promise<QueryResult> => {
    return await promApi.rangeQuery(query, start, end, step, timeout);
  });
}

export function useAlerts(): AsyncState<ExtendedAlert[]> {
  const promApi = useApi(PrometheusApiRef);

  return useAsync(async (): Promise<ExtendedAlert[]> => {
    const alerts = await promApi.alerts();
    return alerts.map(alert => alert as ExtendedAlert);
  });
}
