import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Alert from '@material-ui/lab/Alert';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Progress } from '@backstage/core-components';
import {
  TechDocsReaderPage,
  TechDocsReaderPageContent,
  TechDocsReaderPageSubheader,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { getCompoundEntityRef } from '@backstage/catalog-model';
import { useRoutes } from 'react-router-dom';
import { HideSideBar } from '../../plugin';
import { useRawPage } from '../hooks';
import { DocViewerState, useDocViewerStore } from './store';

const useDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '60%',
      padding: theme.spacing(2.5),
      color: theme.palette.primary.main,
    },
  }),
);

const selector = (state: DocViewerState) => ({
  open: state.open,
  node: state.node,
  closeDrawer: state.closeDrawer,
});

const DrawerContent = () => {
  const { entity } = useEntity();
  const { node } = useDocViewerStore(selector);
  const page = useRawPage(
    node.data.docPath,
    entity.kind,
    entity.metadata.namespace!,
    entity.metadata.name,
  );

  // we can't use EntityTechdocsContent because it uses hooks to map documentation to the entity router
  // this solution work for both embedding and stand-alone viewer page
  //
  // I have no clue why I need to do that, this as side effect of how the addon system is implemented
  // ref: https://github.com/backstage/backstage/blob/41ab5cf4a08ea835e57c5bd482218a96eb068d0c/plugins/techdocs/src/Router.tsx#L59
  const element = useRoutes([
    {
      path: '/*',
      element: (
        <TechDocsReaderPage entityRef={getCompoundEntityRef(entity)}>
          <TechDocsReaderPageSubheader />
          <TechDocsReaderPageContent withSearch={false} />
        </TechDocsReaderPage>
      ),
      children: [
        {
          path: '*',
          element: (
            <TechDocsAddons>
              <HideSideBar />
            </TechDocsAddons>
          ),
        },
      ],
    },
  ]);

  if (page.loading) {
    return <Progress />;
  } else if (page.error) {
    return <Alert severity="error">Page not found: {node.data.docPath}</Alert>;
  }

  return element;
};

export const DocViewer = () => {
  const classes = useDrawerStyles();
  const { open, closeDrawer } = useDocViewerStore(selector);

  return (
    <>
      <Drawer
        classes={{
          paper: classes.paper,
        }}
        anchor="right"
        open={open}
        onClose={closeDrawer}
      >
        <DrawerContent />
      </Drawer>
    </>
  );
};
