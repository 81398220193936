//

// ******************************************************************
// * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. *
// ******************************************************************
import crossFetch from 'cross-fetch';
import * as parser from 'uri-template';
import { DiscoveryApi } from '../types/discovery';
import { FetchApi } from '../types/fetch';
import { pluginId } from '../pluginId';
import { GetBenchmark200Response } from '../models/GetBenchmark200Response.model';
import { GetMetricsByEntityRef200Response } from '../models/GetMetricsByEntityRef200Response.model';
import { HealthGet200Response } from '../models/HealthGet200Response.model';

/**
 * Wraps the Response type to convey a type on the json call.
 *
 * @public
 */
export type TypedResponse<T> = Omit<Response, 'json'> & {
  json: () => Promise<T>;
};

/**
 * Options you can pass into a request for additional information.
 *
 * @public
 */
export interface RequestOptions {
  token?: string;
}
/**
 * @public
 */
export type GetBenchmark = {};
/**
 * @public
 */
export type GetMetricsByEntityRef = {
  path: {
    entityRef: string;
  };
};
/**
 * @public
 */
export type HealthGet = {};

/**
 * @public
 */
export class DefaultApiClient {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: {
    discoveryApi: { getBaseUrl(pluginId: string): Promise<string> };
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
  }

  /**
   * This resource returns the static DORA benchmark we defined for the company
   * Represent the DORA metrics benchmark defined in Backstage
   */
  public async getBenchmark(
    // @ts-ignore
    request: GetBenchmark,
    options?: RequestOptions,
  ): Promise<TypedResponse<GetBenchmark200Response>> {
    const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);

    const uriTemplate = `/benchmark`;

    const uri = parser.parse(uriTemplate).expand({});

    return await this.fetchApi.fetch(`${baseUrl}${uri}`, {
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      method: 'GET',
    });
  }

  /**
   * This resource fetches the DORA metrics from the selected provider  and format the data to be displayed in Backstage
   * Represent the DORA metrics given a Backstage entity
   * @param entityRef - [&lt;kind&gt;:][&lt;namespace&gt;/]&lt;name&gt;
   */
  public async getMetricsByEntityRef(
    // @ts-ignore
    request: GetMetricsByEntityRef,
    options?: RequestOptions,
  ): Promise<TypedResponse<GetMetricsByEntityRef200Response>> {
    const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);

    const uriTemplate = `/metrics/{entityRef}`;

    const uri = parser.parse(uriTemplate).expand({
      entityRef: request.path.entityRef,
    });

    return await this.fetchApi.fetch(`${baseUrl}${uri}`, {
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      method: 'GET',
    });
  }

  /**
   * Represent the healthcheck endpoint
   */
  public async healthGet(
    // @ts-ignore
    request: HealthGet,
    options?: RequestOptions,
  ): Promise<TypedResponse<HealthGet200Response>> {
    const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);

    const uriTemplate = `/health`;

    const uri = parser.parse(uriTemplate).expand({});

    return await this.fetchApi.fetch(`${baseUrl}${uri}`, {
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      method: 'GET',
    });
  }
}
