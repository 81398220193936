import { createContext, useContext } from 'react';
import { create, createStore, useStore } from 'zustand';
import type {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
} from 'react';
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  updateEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
  OnConnectStartParams,
  OnConnectStart,
  HandleType,
  XYPosition,
} from 'reactflow';

import { v4 as uuidv4 } from 'uuid';
import defaultRoadmap from './roadmap.json';

const initialNodes = defaultRoadmap.nodes;
const initialEdges = defaultRoadmap.edges;

export type NodeData = {
  label: string;
  docPath: string | undefined;
  recommend: boolean;
};

export interface Roadmap {
  nodes: Node[];
  edges: Edge[];
}

export type RFState = {
  nodes: Node<NodeData>[];
  edges: Edge[];
  setRoadmap: (roadmap: Roadmap) => void;
  idTracker: number;
  connecting: boolean;
  connectingNode: OnConnectStartParams;
  edgeUpdateSuccessful: boolean;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  onEdgeUpdateStart: (
    event: React.MouseEvent,
    edge: Edge,
    handleType: HandleType,
  ) => void;
  onEdgeUpdateEnd: (
    event: MouseEvent | TouchEvent,
    edge: Edge,
    handleType: HandleType,
  ) => void;
  onConnect: OnConnect;
  onConnectStart: OnConnectStart;
  onConnectEnd: (
    event: MouseEvent | TouchEvent,
    wrapper: HTMLDivElement,
    project: (position: XYPosition) => XYPosition,
  ) => void;
  updateNodeType: (nodeId: string, type: string) => void;
  updateNodeLabel: (nodeId: string, label: string) => void;
  updateNodeDocPath: (nodeId: string, docPath: string | undefined) => void;
  updateNodeRecommend: (nodeId: string, recommend: boolean) => void;
  deleteNode: (nodeId: string) => void;
};

export type RFStore = ReturnType<typeof createRFStore>;

export const RFContext = createContext<RFStore | null>(null);

// We need to used the factory method since we want to create the store from component's props
// https://docs.pmnd.rs/zustand/guides/initialize-state-with-props
export const createRFStore = (initProps?: Partial<Roadmap>) => {
  const DEFAULT_PROPS: Roadmap = {
    nodes: initialNodes,
    edges: initialEdges,
  };

  return createStore<RFState>()((set, get) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    // The roadmap data
    setRoadmap: (roadmap: Roadmap) => {
      set({
        nodes: roadmap.nodes,
        edges: roadmap.edges,
        // idTracker is only used as the default display name of the node
        idTracker: roadmap.nodes.length + 1,
      });
    },
    // Data used to keep track of changes
    idTracker: initialNodes.length + 1,
    connecting: false,
    connectingNode: { handleId: '0', nodeId: '0', handleType: 'source' },
    edgeUpdateSuccessful: false,
    // Mutation functions
    onNodesChange: (changes: NodeChange[]) => {
      set({
        nodes: applyNodeChanges(changes, get().nodes),
      });
    },
    onEdgesChange: (changes: EdgeChange[]) => {
      set({
        edges: applyEdgeChanges(changes, get().edges),
      });
    },
    // Updating edges see -> https://reactflow.dev/docs/examples/edges/delete-edge-on-drop/
    onEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => {
      set({
        edgeUpdateSuccessful: true,
        edges: updateEdge(oldEdge, newConnection, get().edges),
      });
    },
    onEdgeUpdateStart: (
      _event: React.MouseEvent,
      _edge: Edge,
      _handleType: HandleType,
    ) => {
      set({
        edgeUpdateSuccessful: false,
      });
    },
    onEdgeUpdateEnd: (
      _event: MouseEvent | TouchEvent,
      edge: Edge,
      _handleType: HandleType,
    ) => {
      if (!get().edgeUpdateSuccessful) {
        set({
          edges: get().edges.filter(e => e.id !== edge.id),
        });
      }

      set({
        edgeUpdateSuccessful: true,
      });
    },
    onConnect: (connection: Connection) => {
      set({
        edges: addEdge(connection, get().edges),
      });
    },
    // Creating new nodes see -> https://reactflow.dev/docs/examples/nodes/add-node-on-edge-drop/
    onConnectStart: (
      _event: ReactMouseEvent | ReactTouchEvent,
      params: OnConnectStartParams,
    ) => {
      set({
        connecting: true,
        connectingNode: params,
      });
    },
    onConnectEnd: (
      event: MouseEvent | TouchEvent,
      wrapper: HTMLDivElement,
      project: (position: XYPosition) => XYPosition,
    ) => {
      // We should not create node when using a target handle
      if (get().connectingNode.handleType === 'target') {
        return;
      }

      // We check what element is being targeted
      const targetIsPane = (event.target as Element).classList.contains(
        'react-flow__pane',
      );

      if (targetIsPane && get().connecting) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = wrapper.getBoundingClientRect();
        const id = uuidv4();
        const idTracker = get().idTracker++;
        let clientX = 0;
        let clientY = 0;
        if (event instanceof MouseEvent) {
          clientX = (event as MouseEvent).clientX;
          clientY = (event as MouseEvent).clientY;
        } else if (event instanceof TouchEvent) {
          clientX = (event as TouchEvent).touches[0].clientX;
          clientY = (event as TouchEvent).touches[0].clientY;
        }

        // the source handle of a primary is alway null - because is didn't bother naming it
        const isPrimary = get().connectingNode.handleId! === null;

        const newNode = {
          id: `${id}`,
          type: isPrimary ? 'primary' : 'secondary',
          // we are removing the half of the node width (75) to center the new node
          position: project({ x: clientX - left - 75, y: clientY - top }),
          // tslint doesn't let me not set docPath, this is convenient to track the one that are not mapped yet
          data: {
            label: `Node ${idTracker}`,
            docPath: undefined,
            recommend: false,
          },
        };

        set({
          nodes: get().nodes.concat([newNode]),
          edges: addEdge(
            {
              id: `${id}`,
              source: get().connectingNode.nodeId!,
              sourceHandle: get().connectingNode.handleId!,
              target: `${id}`,
              // creating two new edge type to apply style felt overkilled
              animated: isPrimary ? true : false,
              style: isPrimary ? { stroke: 'blue' } : {},
            },
            get().edges,
          ),
          connecting: false,
        });
      }
    },
    // Editing nodes
    updateNodeType: (nodeId: string, type: string) => {
      set({
        nodes: get().nodes.map(node => {
          if (node.id === nodeId) {
            // it's important to create a new object here, to inform React Flow about the changes
            node.type = type;
          }
          return node;
        }),
      });
    },
    updateNodeLabel: (nodeId: string, label: string) => {
      set({
        nodes: get().nodes.map(node => {
          if (node.id === nodeId) {
            // it's important to create a new object here, to inform React Flow about the changes
            node.data = { ...node.data, label };
          }
          return node;
        }),
      });
    },
    updateNodeDocPath: (nodeId: string, docPath: string | undefined) => {
      set({
        nodes: get().nodes.map(node => {
          if (node.id === nodeId) {
            // it's important to create a new object here, to inform React Flow about the changes
            node.data = { ...node.data, docPath };
          }
          return node;
        }),
      });
    },
    updateNodeRecommend: (nodeId: string, recommend: boolean) => {
      set({
        nodes: get().nodes.map(node => {
          if (node.id === nodeId) {
            // it's important to create a new object here, to inform React Flow about the changes
            node.data = { ...node.data, recommend };
          }
          return node;
        }),
      });
    },
    // Delete Bode
    deleteNode: (nodeId: string) => {
      set({
        nodes: get().nodes.filter(node => node.id !== nodeId),
      });
    },
  }));
};

// create a custom hook
export function useRFStore<T>(
  selector: (state: RFState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(RFContext);
  if (!store) throw new Error('Missing RFContext.Provider in the tree');
  return useStore(store, selector, equalityFn);
}

// Node editor
export type DialogState = {
  open: boolean;
  node: Node<NodeData>;
  setNode: (node: Node) => void;
  openDialog: (event: React.MouseEvent, node: Node) => void;
  closeDialog: () => void;
};

export const useDialogStore = create<DialogState>(set => ({
  open: false,
  node: initialNodes[0],
  setNode: (node: Node) => set({ node: node }),
  openDialog: (_event: React.MouseEvent, node: Node) =>
    set({
      open: true,
      node: node,
    }),
  closeDialog: () => set({ open: false }),
}));

// Doc creation dialog
export type DocDialogState = {
  open: boolean;
  node: Node<NodeData>;
  setNode: (node: Node) => void;
  openDialog: (event: React.MouseEvent) => void;
  closeDialog: () => void;
};

export const useDocDialogStore = create<DocDialogState>(set => ({
  open: false,
  node: initialNodes[0],
  setNode: (node: Node) => set({ node: node }),
  openDialog: (_event: React.MouseEvent) =>
    set({
      open: true,
    }),
  closeDialog: () => set({ open: false }),
}));
