import React, { useRef } from 'react';
import ReactFlow, { useReactFlow, ReactFlowProvider } from 'reactflow';
import { TabbedLayout, Progress } from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';
import { Entity, getCompoundEntityRef } from '@backstage/catalog-model';

import 'reactflow/dist/style.css';

import { makeStyles, createStyles, AppBar, Toolbar } from '@material-ui/core';
import PrimaryNode from '../RoadmapNodes/PrimaryNode';
import SecondaryNode from '../RoadmapNodes/SecondaryNode';
import { useRawRoadmap } from '../hooks';
import { TECHDOCS_ROADMAPS_ANNOTATION } from '../../annotations';
import {
  RFStore,
  RFState,
  RFContext,
  createRFStore,
  useRFStore,
  useDialogStore,
  DialogState,
} from './store';
import { NodeEditor } from './NodeEditor';
import { CodeViewer } from './CodeViewer';
import { CodeSavingActionDialog } from './CodeSavingActionDialog';

import { DocMapper } from './DocMapper';

const nodeTypes = { primary: PrimaryNode, secondary: SecondaryNode };

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onEdgeUpdate: state.onEdgeUpdate,
  onEdgeUpdateStart: state.onEdgeUpdateStart,
  onEdgeUpdateEnd: state.onEdgeUpdateEnd,
  onConnect: state.onConnect,
  onConnectStart: state.onConnectStart,
  onConnectEnd: state.onConnectEnd,
  setRoadmap: state.setRoadmap,
});

const selectorDialog = (state: DialogState) => ({
  openDialog: state.openDialog,
});

// In order to access the state we need ReactFlowProvider as the parent of out component
// https://reactflow.dev/docs/guides/troubleshooting/#warning-seems-like-you-have-not-used-zustand-provider-as-an-ancestor
const Flow = () => {
  // we need to know the position of the wrapper to calculate node position
  const wrapper = useRef(null);
  const { project } = useReactFlow();
  // Our component store
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onConnectStart,
    onConnectEnd,
    onEdgeUpdate,
    onEdgeUpdateEnd,
    onEdgeUpdateStart,
  } = useRFStore(selector);
  // The node editor Store
  const { openDialog } = useDialogStore(selectorDialog);

  // handleOnConnectEnd need to be linked to wrapper (screen pos) and
  // use the project function to calculate relative position of nodes
  const handleOnConnectEnd = (event: MouseEvent | TouchEvent) => {
    onConnectEnd(event, wrapper.current!, project);
  };

  const useStyles = makeStyles(() =>
    createStyles({
      flowWrapper: {
        height: '90vh',
        margin: 0,
        padding: 0,
        boxSizin: 'border-box',
        fontFamily: 'sans-serif',
        '& .editor': {
          backgroundColor: 'lightgrey',
        },
        '& > .react-flow__handle': {
          width: '10px',
          height: '10px',
          borderRadius: '3px',
        },
        '& .react-flow__node-primary .react-flow__handle': {
          width: '8px',
          height: '8px',
          borderRadius: '3px',
        },
        '& > .react-flow__node-primary .react-flow__handle-top': {
          width: '30px',
          height: '14px',
          borderRadius: '3px',
          backgroundColor: '#784be8',
          top: '-10px',
        },
        '& .react-flow__node-primary .react-flow__handle-bottom': {
          width: '30px',
          height: '14px',
          borderRadius: '3px',
          backgroundColor: '#784be8',
          bottom: '-10px',
        },
        '& .react-flow__node': {
          minHeight: '40px',
          minWidth: '150px',
          maxWidth: '250px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          borderWidth: '2px',
          fontWeight: 700,
          padding: '5px',
        },
        '& .react-flow__edge path': {
          strokeWidth: '2',
        },
        '& .react-flow__connectionline path': {
          strokeWidth: '2',
        },
      },
    }),
  );

  const classes = useStyles();
  return (
    <div className={classes.flowWrapper} id="reactFlowWrapper" ref={wrapper}>
      <NodeEditor />
      <ReactFlow
        className="editor"
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={handleOnConnectEnd}
        onNodeClick={openDialog}
        nodeTypes={nodeTypes}
        fitView
      />
    </div>
  );
};

export const RoadmapEditorComponent = (props: { entity: Entity }) => {
  const RFStoreRef = useRef<RFStore>();
  const roadmapPath =
    props.entity.metadata.annotations![TECHDOCS_ROADMAPS_ANNOTATION] ??
    'notfound';
  const roadmap = useRawRoadmap(
    roadmapPath.replace('docs/', ''),
    getCompoundEntityRef(props.entity),
  );

  if (roadmap.loading) {
    return <Progress />;
  } else if (roadmap.error && roadmapPath !== 'notfound') {
    return (
      <Alert severity="error" style={{ whiteSpace: 'pre-line' }}>
        {roadmap.error.message}
      </Alert>
    );
  }

  if (!RFStoreRef.current) {
    RFStoreRef.current = createRFStore(roadmap.value?.content);
  }

  // I had to create RFContext.Provider to be able to initialise the state/store
  // This allow to use the hooks from that store in the child components
  // It looks very similar to ReactFlowProvider we also use here to access ReactFlow hooks

  return (
    <RFContext.Provider value={RFStoreRef.current}>
      <AppBar position="relative">
        <Toolbar>
          <CodeSavingActionDialog />
        </Toolbar>
      </AppBar>
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Editor">
          <ReactFlowProvider>
            <Flow />
          </ReactFlowProvider>
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/doc" title="Documentation">
          <DocMapper entity={props.entity} />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/code" title="Copy Code">
          <CodeViewer />
        </TabbedLayout.Route>
      </TabbedLayout>
    </RFContext.Provider>
  );
};
