import { PrometheusMatrixVectorResult } from './types';

export function resultToGraphData(
  result: PrometheusMatrixVectorResult[],
  dimension?: string,
) {
  const keys: string[] = [];
  const metrics: { [k: string]: any } = {};

  if (result.length === 0) {
    return { keys, metrics, data: [] };
  }

  const grouper =
    dimension &&
    result.every(it => Object.keys(it.metric.labels).includes(dimension))
      ? dimension
      : Object.keys(result[0].metric.labels)[0];

  const flatMappedData = result.flatMap(it => {
    const groupKey =
      grouper === 'name' ? it.metric.name : it.metric.labels[grouper];

    if (!keys.includes(groupKey)) {
      keys.push(groupKey);
    }
    if (!metrics[groupKey]) {
      metrics[groupKey] = it.metric.labels;
    }

    return it.values.map(val => ({
      time: val.time,
      [groupKey]: val.value,
    }));
  });

  const groupedData = Object.values(
    flatMappedData.reduce(
      (acc, entry) => {
        const time = entry.time;
        acc[time] = acc[time] ? { ...acc[time], ...entry } : entry;
        return acc;
      },
      {} as Record<string, any>,
    ),
  );

  return { data: groupedData, keys, metrics };
}
