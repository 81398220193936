import React, { useMemo } from 'react';
import moment from 'moment';
import { Table, TableColumn } from '@backstage/core-components';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { BackstageFollowup } from '../types';

type FollowUpTableProps = {
  followups: BackstageFollowup[];
  loading: boolean;
  baseUrl: string;
};

const followUpCellStyle = { paddingBottom: '5px', paddingTop: '5px' };

export const FollowupTable = ({
  followups,
  loading,
  baseUrl,
}: FollowUpTableProps) => {
  const columns = useMemo<TableColumn<BackstageFollowup>[]>(
    () => [
      {
        title: 'Title',
        field: 'title',
        cellStyle: followUpCellStyle,
      },
      {
        title: 'Assignee',
        field: 'assignee.backstage_user',
        cellStyle: followUpCellStyle,
        render: rowData =>
          rowData.assignee?.backstage_user ? (
            <EntityRefLink
              entityRef={`user:${rowData.assignee.backstage_user}`}
              defaultKind="User"
            />
          ) : null,
      },
      {
        title: 'Created',
        field: 'created_at',
        type: 'datetime',
        cellStyle: followUpCellStyle,
        defaultSort: 'desc',
        render: rowData =>
          rowData.created_at ? moment(rowData.created_at).fromNow() : 'Unknown',
      },
      {
        title: 'Priority',
        field: 'priority.name',
        cellStyle: followUpCellStyle,
      },
      {
        title: 'Jira',
        field: 'external_issue_reference',
        cellStyle: followUpCellStyle,
        render: rowData => {
          const issueRef = rowData.external_issue_reference;
          if (!issueRef?.issue_permalink || !issueRef?.issue_name) {
            return null;
          }
          return (
            <Typography color="inherit">
              <Link color="inherit" href={issueRef.issue_permalink}>
                {issueRef.issue_name}
              </Link>
            </Typography>
          );
        },
      },
      {
        title: 'Incident',
        field: 'incident_id',
        cellStyle: followUpCellStyle,
        render: (rowData: any) => {
          if (!rowData.incident_id) {
            return 'N/A';
          }
          const incidentUrl = `${baseUrl}/incidents/${rowData.incident_id}?tab=post-incident`;
          return (
            <Tooltip title="View in incident.io" placement="top">
              <IconButton
                href={incidentUrl}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <OpenInBrowserIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [baseUrl],
  );

  return (
    <Table
      isLoading={loading}
      title="Outstanding Follow Ups"
      options={{
        padding: 'dense',
        search: true,
        paging: true,
        pageSize: 5,
        debounceInterval: 0,
      }}
      columns={columns}
      data={followups}
    />
  );
};
