import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import {
  techdocsStorageApiRef,
  techdocsApiRef,
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { Entity } from '@backstage/catalog-model';
import {
  entityRoadmapViewerRouteRef,
  entityRoadmapEditorRouteRef,
  rootRoadmapRouteRef,
  rootRoadmapEditorRouteRef,
} from './routes';
import { TECHDOCS_ROADMAPS_ANNOTATION } from './annotations';
import { RoadmapStorageApiClient, roadmapStorageApiRef } from './api';
import { HideSideBarTechDocsAddon } from './components/RoadmapViewerComponent/HideSideBarTechDocsAddon';

export const isRoadmapAnnotationAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[TECHDOCS_ROADMAPS_ANNOTATION]);

export const techdocRoadmapsStorageApiFactory = createApiFactory({
  api: roadmapStorageApiRef,
  deps: {
    techdocsStorageApi: techdocsStorageApiRef,
    techdocApi: techdocsApiRef,
    fetchApi: fetchApiRef,
  },
  factory: ({ techdocsStorageApi, techdocApi, fetchApi }) =>
    new RoadmapStorageApiClient({ techdocsStorageApi, techdocApi, fetchApi }),
});

export const techdocRoadmapsAndGoldenpathPlugin = createPlugin({
  id: 'techdoc-roadmaps-and-goldenpath',
  routes: {
    root: rootRoadmapRouteRef,
    entityViewer: entityRoadmapEditorRouteRef,
    entityEditor: entityRoadmapViewerRouteRef,
  },
  apis: [techdocRoadmapsStorageApiFactory],
});

/*
 * TechdocRoadmapsAndGoldenpathDefaultHomePage display the list of existing Roadmaps or GoldenPath
 */
export const TechdocRoadmapsAndGoldenpathDefaultHomePage =
  techdocRoadmapsAndGoldenpathPlugin.provide(
    createRoutableExtension({
      name: 'RoadmapHomePage',
      component: () =>
        import('./components/RoadmapHomePage').then(m => m.RoadmapHomePage),
      mountPoint: rootRoadmapRouteRef,
    }),
  );

/*
 * TechdocRoadmapsAndGoldenpathEditorPage provide a visual editor for the Roadmaps or GoldenPath
 */
export const TechdocRoadmapsAndGoldenpathEditorPage =
  techdocRoadmapsAndGoldenpathPlugin.provide(
    createRoutableExtension({
      name: 'TechdocRoadmapsAndGoldenpathEditorPage',
      component: () =>
        import('./components/RoadmapEditorComponent').then(
          m => m.RoadmapEditorPage,
        ),
      mountPoint: entityRoadmapEditorRouteRef,
    }),
  );

/*
 * TechdocRoadmapsAndGoldenpathCreatorPage allows to create a new Roadmaps or GoldenPath via Backstage UI
 */
export const TechdocRoadmapsAndGoldenpathCreatorPage =
  techdocRoadmapsAndGoldenpathPlugin.provide(
    createRoutableExtension({
      name: 'TechdocRoadmapsAndGoldenpathEditorPage',
      component: () =>
        import('./components/RoadmapEditorComponent').then(
          m => m.RoadmapCreatorPage,
        ),
      mountPoint: rootRoadmapEditorRouteRef,
    }),
  );

/*
 * TechdocRoadmapsAndGoldenpathViewerPage provide a dedicated viewer page that can be used instead of embedding it to the entity Page
 */
export const TechdocRoadmapsAndGoldenpathViewerPage =
  techdocRoadmapsAndGoldenpathPlugin.provide(
    createRoutableExtension({
      name: 'TechdocRoadmapsAndGoldenpathViewerPage',
      component: () =>
        import('./components/RoadmapViewerComponent').then(
          m => m.RoadmapViewerPage,
        ),
      mountPoint: entityRoadmapViewerRouteRef,
    }),
  );

/*
 * HideSideBar is used to adjust the techdocs content in the doc viewer panel notably remove the side navigation
 */
export const HideSideBar = techdocRoadmapsAndGoldenpathPlugin.provide(
  // This function "creates" the Addon given a component and location. If your
  // component can be configured via props, pass the prop type here too.
  createTechDocsAddonExtension({
    name: 'HideSideBarTechDocsAddon',
    location: TechDocsAddonLocations.Content,
    component: HideSideBarTechDocsAddon,
  }),
);
