import {
  createApiFactory,
  createApiRef,
  createComponentExtension,
  createPlugin,
  createReactExtension,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { DefaultDoraMetricsApi, DoraMetricsApi } from './apis/doraMetricsApi';

export const doraMetricsApiRef = createApiRef<DoraMetricsApi>({
  id: 'dora-metrics.api',
});

/**
 * doraMetricsApiFactory provide a default way to instantiate the api client for that plugin
 */
export const doraMetricsApiFactory = createApiFactory({
  api: doraMetricsApiRef,
  deps: {
    fetchApi: fetchApiRef,
    discoveryApi: discoveryApiRef,
  },
  factory: ({ fetchApi, discoveryApi }) =>
    new DefaultDoraMetricsApi({
      fetchApi: fetchApi,
      discoveryApi: discoveryApi,
    }),
});

export const doraMetricsPlugin = createPlugin({
  id: 'dora-metrics',
  apis: [doraMetricsApiFactory],
  routes: {
    root: rootRouteRef,
  },
});

export const DoraMetricsPage = doraMetricsPlugin.provide(
  createRoutableExtension({
    name: 'DoraMetricsPage',
    component: () =>
      import('./components/DoraMetricsOverviewPage').then(
        m => m.DoraMetricsOverviewPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const DoraMetricsGroupCard = doraMetricsPlugin.provide(
  createComponentExtension({
    name: 'DoraMetricsGroupCard',
    component: {
      lazy: () =>
        import('./components/DoraMetricsCard').then(
          m => m.DoraMetricsGroupCard,
        ),
    },
  }),
);

export const DoraMetricsHomeCard = doraMetricsPlugin.provide(
  createReactExtension({
    name: 'DoraMetricsHomeCard',
    component: {
      lazy: () =>
        import('./components/DoraMetricsCard').then(m => m.DoraMetricsHomeCard),
    },
    data: {
      'home.dora.config': {
        title: 'DORA Metrics',
        layout: {
          height: { defaultColumns: 6, defaultRows: 3 },
        },
      },
    },
  }),
);
