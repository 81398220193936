import React from 'react';

import { Page, Header, HeaderLabel, Content } from '@backstage/core-components';

import { makeStyles } from '@material-ui/core';
import { RoadmapEditorComponent } from './RoadmapEditorComponent';

const useStyles = makeStyles(
  {
    content: {
      padding: 0,
    },
  },
  { name: 'RoadmapCreatorPage' },
);

/*
 * RoadmapCreatorPage allow creating a new Roadmap
 * TODO: allow binding or creating a new entity
 */
export const RoadmapCreatorPage = () => {
  const classes = useStyles();
  // dummy entity, in the future we can use this for the binding
  const entity = {
    apiVersion: 'backstage.io/v1alpha1',
    kind: 'Resource',
    metadata: {
      name: 'New Roadmap',
      annotations: {},
    },
  };

  return (
    <Page themeId="documentation">
      <Header
        title="RoadMap Creator"
        subtitle="Create the Visual layout for a new Roadmaps or Golden Paths"
      >
        <HeaderLabel label="Name" value={entity.metadata.name} />
        <HeaderLabel label="Roadmap Path" value="undefined" />
      </Header>
      <Content className={classes.content}>
        <RoadmapEditorComponent entity={entity} />
      </Content>
    </Page>
  );
};
