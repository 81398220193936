import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Table, TableColumn, Progress } from '@backstage/core-components';
import { Entity, getCompoundEntityRef } from '@backstage/catalog-model';

import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import NoteAdd from '@material-ui/icons/NoteAdd';
import { green, red } from '@material-ui/core/colors';
import { useRawDocMetadata } from '../hooks';
import { DocsMetadata } from '../../api/RoadmapStorageApi';
import { DocCreationActionDialog } from './DocCreationActionDialog';
import {
  useRFStore,
  RFState,
  useDocDialogStore,
  DocDialogState,
} from './store';

const useStyles = makeStyles(theme => ({
  container: {
    width: 1200,
  },
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  setRoadmap: state.setRoadmap,
  updateNodeDocPath: state.updateNodeDocPath,
});

const selectorDialog = (state: DocDialogState) => ({
  open: state.open,
  node: state.node,
  setNode: state.setNode,
  openDialog: state.openDialog,
  closeDialog: state.closeDialog,
});

const Status = (props: { rowData: any }) => {
  if (props.rowData.data.docPath !== undefined) {
    return <CheckBoxIcon />;
  }

  return <></>;
};

const Action = (props: { rowData: any; metadata: string[] }) => {
  const { updateNodeDocPath } = useRFStore(selector);
  const { openDialog } = useDocDialogStore(selectorDialog);

  if (props.rowData.data.docPath === undefined) {
    return (
      <Button onClick={openDialog}>
        <NoteAdd style={{ color: green[500] }} />
      </Button>
    );
  }

  const handleNodeDocPathChange = (_event: any) => {
    updateNodeDocPath(props.rowData.id, undefined);
    props.rowData.data.docPath = undefined;
  };

  return (
    <Button onClick={handleNodeDocPathChange}>
      <DeleteForever style={{ color: red[500] }} />
    </Button>
  );
};

// Typing this is a messy because I don't know how to type the columns properly
const Mapping = (props: { rowData: any; metadata: string[] }) => {
  const { updateNodeDocPath } = useRFStore(selector);

  if (props.rowData.data.docPath !== undefined) {
    return props.rowData.data.docPath;
  }

  const handleNodeDocPathChange = (event: any) => {
    const docPath = event.target.value as string;
    updateNodeDocPath(props.rowData.id, docPath);
    props.rowData.data.docPath = docPath;
  };

  return (
    <Select
      value={props.rowData.data.docPath}
      onChange={handleNodeDocPathChange}
    >
      {props.metadata.map((value: string) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};

export const DocMapper = (props: { entity: Entity }) => {
  const { nodes } = useRFStore(selector);
  const classes = useStyles();
  const metadata = useRawDocMetadata(getCompoundEntityRef(props.entity));

  if (metadata.loading) {
    return <Progress />;
  } else if (metadata.error) {
    return (
      <Alert severity="error" style={{ whiteSpace: 'pre-line' }}>
        {metadata.error.message}
      </Alert>
    );
  } else if (metadata.value === undefined) {
    return (
      <Alert severity="error">Failed to fetch documentation metadata</Alert>
    );
  }

  const filteredMetadata = filterMetadata(metadata.value?.content);

  const columns: TableColumn[] = [
    {
      title: 'Done',
      render: rowData => {
        return <Status rowData={rowData} />;
      },
      width: '5%',
    },
    {
      title: 'Nodes labels',
      field: 'data.label',
      highlight: true,
      cellStyle: (_, rowData: any & { tableData: { id: number } }) => {
        return rowData.type === 'primary'
          ? {
              color: 'gold',
            }
          : {
              color: 'orange',
            };
      },
    },
    {
      title: 'Type',
      field: 'type',
      hidden: true,
    },
    {
      title: 'Doc',
      field: 'data.docPath',
      render: rowData => {
        return <Mapping rowData={rowData} metadata={filteredMetadata} />;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        return <Action rowData={rowData} metadata={filteredMetadata} />;
      },
      width: '5%',
    },
  ];

  return (
    <div className={classes.container}>
      <DocCreationActionDialog />
      <Table
        options={{ paging: false }}
        data={nodes}
        columns={columns}
        title="Backstage Table"
      />
    </div>
  );
};

function filterMetadata(metadata: DocsMetadata): string[] {
  if (metadata.files === undefined) {
    return [];
  }

  const files = metadata.files?.reduce((acc, file) => {
    if (!file.endsWith('/index.html')) {
      return acc;
    }

    return [...acc, file.replace('/index.html', '')];
  }, [] as string[]);

  return files;
}
