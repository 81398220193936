import {
  Alert,
  PrometheusDriver,
  PrometheusQueryDate,
  QueryResult,
} from 'prometheus-query';
import {
  ConfigApi,
  createApiRef,
  IdentityApi,
} from '@backstage/core-plugin-api';

/**
 * API to interact with a Prometheus backend to query timeseries or alerts
 */
export interface PrometheusApi {
  instantQuery(
    query: string,
    time?: PrometheusQueryDate,
    timeout?: string,
  ): Promise<QueryResult>;
  rangeQuery(
    query: string,
    start: PrometheusQueryDate,
    end: PrometheusQueryDate,
    step: string | number,
    timeout?: string,
  ): Promise<QueryResult>;
  alerts(): Promise<Alert[]>;
}

export const PrometheusApiRef = createApiRef<PrometheusApi>({
  id: 'prometheus.api',
});

export class DefaultPrometheusApi implements PrometheusApi {
  private readonly prom: PrometheusDriver;
  private readonly promAlerts: PrometheusDriver;
  private readonly configApi: ConfigApi;
  private readonly identityApi: IdentityApi;
  private readonly prometheusPrefix = '/prometheus';

  constructor(options: { configApi: ConfigApi; identityApi: IdentityApi }) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    // Headers are configured in the proxy
    this.prom = new PrometheusDriver({
      endpoint: `${this.configApi.getString('backend.baseUrl')}/api/proxy${this.prometheusPrefix}`,
      headers: {
        'X-Scope-OrgID': 'faceit|infra',
      },
      requestInterceptor: {
        onFulfilled: async config => {
          const credentials = await this.identityApi.getCredentials();
          config.headers.Authorization = `Bearer ${credentials.token}`;
          return config;
        },
      },
    });
    // Initializing the driver twice because Mimir Ruler is single tenant https://github.com/grafana/mimir/issues/8165
    // Should swap from PrometheusDriver in the future.
    this.promAlerts = new PrometheusDriver({
      endpoint: `${this.configApi.getString('backend.baseUrl')}/api/proxy${this.prometheusPrefix}`,
      headers: {
        'X-Scope-OrgID': 'faceit',
      },
      requestInterceptor: {
        onFulfilled: async config => {
          const credentials = await this.identityApi.getCredentials();
          config.headers.Authorization = `Bearer ${credentials.token}`;
          return config;
        },
      },
    });
  }

  instantQuery(
    query: string,
    time?: PrometheusQueryDate,
    timeout?: string,
  ): Promise<QueryResult> {
    return this.prom.instantQuery(query, time, timeout);
  }

  rangeQuery(
    query: string,
    start: PrometheusQueryDate,
    end: PrometheusQueryDate,
    step: string | number,
    timeout?: string,
  ): Promise<QueryResult> {
    return this.prom.rangeQuery(query, start, end, step, timeout);
  }

  alerts(): Promise<Alert[]> {
    return this.promAlerts.alerts();
  }
}
