import { InfoCard, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { GroupEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useInstantQuery } from '@efg/backstage-plugin-prometheus';
import { Typography } from '@material-ui/core';
import { Duration } from 'luxon';

export const DowntimeCard = () => {
  const { entity: group } = useEntity<GroupEntity>();
  const query = `max by (team) (last_over_time(faceit_com_animals_team_downtime{team="${group.metadata.name}"}[7d]))`;

  const { loading, error, value } = useInstantQuery(query);

  if (loading) return <Progress />;
  if (error) {
    return (
      <Alert severity="error">
        {error?.message || 'An unknown error occurred'}
      </Alert>
    );
  }

  if (!value?.result || value.result.length === 0) {
    return <Alert severity="warning">No Downtime data available.</Alert>;
  }

  const downtimeHours = value.result[0]?.value?.value ?? 0;

  const formatTime = (totalHours: number): string => {
    const duration = Duration.fromObject({ hours: totalHours });

    if (duration.as('hours') >= 1) {
      return duration
        .toFormat("h 'hours' m 'minutes' s 'seconds'")
        .replace(/ 0 (minutes|seconds)/g, '');
    } else if (duration.as('minutes') >= 1) {
      return duration
        .toFormat("m 'minutes' s 'seconds'")
        .replace(/ 0 seconds/, '');
    }
    return duration.toFormat("s 'seconds'");
  };
  const downtime = formatTime(downtimeHours);

  return (
    <>
      <InfoCard
        variant="flex"
        title={
          <Typography style={{ display: 'flex', alignItems: 'center' }}>
            DOWNTIME
          </Typography>
        }
      >
        <Typography variant="h6">{downtime}</Typography>
      </InfoCard>
    </>
  );
};
