import React from 'react';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { IncidentApiRef } from '@efg/incident-io-backstage';
import { GroupEntity } from '@backstage/catalog-model';
import { AsyncState } from 'react-use/lib/useAsync';
import { BackstageFollowup, Followup, FollowupsResponse } from '../types';

const jiraAnnotation = 'jira.com/project-key';

function followupHasTeamMembers(teamMembers: string[]) {
  return (followup: Followup) => {
    if (!followup.assignee?.email || followup.status !== 'outstanding')
      return false;
    const assigneeEmail = followup.assignee.email.split('@')[0].toLowerCase();

    return teamMembers.includes(assigneeEmail);
  };
}

function addBackstageUserAssignee() {
  return (followup: Followup): BackstageFollowup => {
    if (!followup.assignee) {
      const { assignee, ...rest } = followup;
      return rest as BackstageFollowup;
    }

    return {
      ...followup,
      assignee: {
        ...followup.assignee,
        backstage_user: followup.assignee.email.includes('.ext.')
          ? `ext/${followup.assignee.email.split('@')[0].toLowerCase()}`
          : `default/${followup.assignee.email.split('@')[0].toLowerCase()}`,
      },
    };
  };
}

export const useGroupFollowups = (
  group: GroupEntity,
  deps?: React.DependencyList,
): AsyncState<BackstageFollowup[]> => {
  const IncidentApi = useApi(IncidentApiRef);
  return useAsync(async (): Promise<BackstageFollowup[]> => {
    const { follow_ups } = await IncidentApi.request<FollowupsResponse>({
      path: `/v2/follow_ups?`,
    });
    const teamMembers =
      group.spec.members?.map((member: string) =>
        member.replace(/^user:(default|ext)\//, ''),
      ) ?? [];

    const jiraProjectKey = group.metadata.annotations?.[jiraAnnotation];
    const jiraPrefix = `${jiraProjectKey}-`;

    return (
      follow_ups
        ?.filter(followup => {
          const jira_ticket = followup.external_issue_reference?.issue_name;
          if (
            followup.status === 'outstanding' &&
            jira_ticket &&
            jiraProjectKey &&
            jira_ticket.startsWith(jiraPrefix)
          ) {
            return true;
          }
          return !jira_ticket && followupHasTeamMembers(teamMembers)(followup);
        })
        .map(addBackstageUserAssignee()) ?? []
    );
  }, deps);
};
