import { InfoCard, Progress } from '@backstage/core-components';
import React from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { GroupEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useIncidentIOURL } from '../config';
import { useGroupFollowups } from '../hooks';
import { FollowupTable } from './FollowupTable';

export const FollowUpCard = () => {
  const { entity: group } = useEntity<GroupEntity>();
  const baseURL = useIncidentIOURL();
  const { loading, error, value: followups } = useGroupFollowups(group);

  if (loading) return <Progress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <>
      {followups && followups?.length === 0 && (
        <InfoCard title="Outstanding Follow Ups" variant="fullHeight">
          <Typography variant="subtitle1">
            No outstanding follow-ups 👍
          </Typography>
        </InfoCard>
      )}
      {followups && followups.length > 0 && (
        <FollowupTable
          followups={followups}
          loading={loading}
          baseUrl={baseURL}
        />
      )}
    </>
  );
};
