import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  createRouteRef,
  discoveryApiRef,
  identityApiRef,
  createComponentExtension,
} from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { AnimalsClient, animalsApiRef } from './api/AnimalsApi';

export const SEMANTIC_RELEASES_ANNOTATION = 'efg.gg/semantic-releases';
export const ANIMALS_ANNOTATION_ENVIRONMENT = 'efg.gg/animals-cd-environment';
export const isAnimalsProjectIDAnnotationAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[ANIMALS_ANNOTATION_ENVIRONMENT]);

export const rootRouteRef = createRouteRef({
  id: 'animals-deploy',
});

export const entityRootRouteRef = createRouteRef({
  id: 'animals-deploy:entity',
  params: ['namespace', 'kind', 'name'],
});

export const animalsDeployPlugin = createPlugin({
  id: 'animals-deploy',
  featureFlags: [{ name: 'use-legacy-version-api' }],
  routes: {
    root: rootRouteRef,
    entityRoot: entityRootRouteRef,
  },
  apis: [
    createApiFactory({
      api: animalsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, identityApi }) =>
        new AnimalsClient({
          discoveryApi,
          identityApi,
        }),
    }),
  ],
});

export const AnimalsDeploymentsPage = animalsDeployPlugin.provide(
  createRoutableExtension({
    name: 'AnimalsDeploymentsPage',
    component: () =>
      import('./components/DeploymentsPage').then(m => m.DeploymentsPage),
    mountPoint: rootRouteRef,
  }),
);

export const EntityAnimalsDeploymentsContent = animalsDeployPlugin.provide(
  createComponentExtension({
    name: 'EntityAnimalsDeploymentsContent',
    component: {
      lazy: () =>
        import('./components/EntityDeploymentsContent').then(
          m => m.EntityDeploymentsContent,
        ),
    },
  }),
);

export const EntityAnimalsDeployerCard = animalsDeployPlugin.provide(
  createComponentExtension({
    name: 'EntityAnimalsDeployerCard',
    component: {
      lazy: () =>
        import('./components/EntityDeployerCard').then(
          m => m.EntityDeployerCard,
        ),
    },
  }),
);

export const EntityAnimalsVersionsDeployerCard = animalsDeployPlugin.provide(
  createComponentExtension({
    name: 'EntityAnimalsDeployerCard',
    component: {
      lazy: () =>
        import('./components/EntityDeployerCard').then(
          m => m.EntityDeployerCard,
        ),
    },
  }),
);
