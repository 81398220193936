/**
 * Converts seconds to a string representation of days.
 * This function only works when the seconds are equal to an exact number of days.
 * @param seconds - The number of seconds to convert
 * @returns A string in the format "Xd" where X is the number of days
 * @example
 * secondsToDays(86400) // returns "1d"
 * secondsToDays(172800) // returns "2d"
 */
export const secondsToDays = (seconds: number): string => {
  const days = Math.floor(seconds / 86400);
  return `${days}d`;
};

/**
 * Calculates the remaining error budget based on the SLO objective and current availability
 * @param objective - The SLO objective as a decimal (e.g., 0.99 for 99%)
 * @param availability - The current availability as a decimal (e.g., 0.95 for 95%)
 * @returns A number between -Inf and 1 representing the error budget:
 *          - Positive values indicate remaining budget
 *          - Negative values indicate exceeded budget
 *          - Returns 0 if availability or objective is 0 (default value)
 * @example
 * calculateErrorBudget(0.99, 0.995) // returns 0.5 (50% of error budget remaining)
 * calculateErrorBudget(0.99, 0.985) // returns -0.5 (50% over error budget)
 */
export const calculateErrorBudget = (
  objective: number,
  availability: number,
): number => {
  if (availability === 0 || objective === 0) return 0;
  return (availability - objective) / (1 - objective);
};
