import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  GetMetricsByEntityRef200Response,
  DefaultApiClient,
} from '@efg/backstage-plugin-dora-metrics-common';
import { doraMetricsPlugin } from '../plugin';

export interface DoraMetricsApi {
  getEntityMetrics(
    entity: GetEntityMetricsRequest,
  ): Promise<GetMetricsByEntityRef200Response>;
}

export interface GetEntityMetricsRequest {
  entity: Entity;
}

export class DefaultDoraMetricsApi implements DoraMetricsApi {
  private readonly apiClient: DefaultApiClient;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.apiClient = new DefaultApiClient(options);
  }

  /**
   * Handles errors when talking to the backend plugin
   */
  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      const payload = await response.text();
      let message;
      switch (response.status) {
        case 400:
          message = `Invalid Request from ${doraMetricsPlugin.getId()} Backend (HTTP 400). This is a bug please report it.`;
          break;
        case 404:
          message = `Could not find the ${doraMetricsPlugin.getId()} Backend (HTTP 404). Make sure the plugin has been fully installed.`;
          break;
        default:
          message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      }
      throw new Error(message);
    }

    return await response.json();
  }

  async getEntityMetrics(
    request: GetEntityMetricsRequest,
  ): Promise<GetMetricsByEntityRef200Response> {
    const response = await this.apiClient.getMetricsByEntityRef({
      path: {
        entityRef: stringifyEntityRef(request.entity),
      },
    });

    return this.handleResponse(response);
  }
}
