import {
  calculateErrorBudget,
  secondsToDays,
  SLO,
} from '@efg/backstage-plugin-slo-common';
import { createApiRef } from '@backstage/core-plugin-api';
import { PrometheusApi } from '@efg/backstage-plugin-prometheus';
import { budgetAtRiskPercent } from '../consts';

/** sloQueries is a list of Prometheus metrics used to get SLO data */
const sloQueries = {
  objective: 'pyrra_objective',
  availability: 'pyrra_availability',
  window: 'pyrra_window',
  errors_total: 'pyrra_errors_total',
  requests_total: 'pyrra_requests_total',
};

/**
 * Filter options for querying Service Level Objectives (SLOs)
 * All filters support regular expressions and are optional.
 * When not provided, they should default to '.*' (match everything).
 * @example
 * // Filter all SLOs for team devxp
 * ```ts
 * const filter = { team: 'devxp' };
 * ```
 *
 * // Filter all auth-api SLOs
 * ```ts
 * const filter = { app: 'auth-api' };
 * ```
 *
 */
export interface SLOFilter {
  /** Team owning the SLO (supports regex) */
  team?: string;
  /** Service the SLO belongs to (supports regex) */
  service?: string;
  /** Application the SLO belongs to (supports regex) */
  app?: string;
  /** Type of SLO (e.g. 'availability', 'latency') (supports regex) */
  kind?: string;
  /** Name of the SLO (supports regex) */
  name?: string;
  /** Filter to only show "at risk" SLOs, where the remaining error budget is less than 10% */
  onlyAtRisk?: boolean;
}

/**
 * API to interact with a Prometheus backend to fetch Service Level Objectives (SLOs) data.
 */
export interface SLOApi {
  /**
   * Returns a list of Service Level Objectives (SLOs) based on the provided filters.
   *
   * @param filter - Optional {@link SLOFilter} to narrow down SLO results
   *
   * @example
   * // Get all SLOs
   * ```ts
   * const slos = await sloApi.listSLOs();
   * ```
   *
   * // Get SLOs for team tech
   * ```ts
   * const teamSLOs = await sloApi.listSlos({ team: 'tech' });
   * ```
   * @returns Promise resolving to an array of {@link SLO}
   */
  listSLOs(filter?: SLOFilter): Promise<SLO[]>;
}

export const SLOApiRef = createApiRef<SLOApi>({
  id: 'slo.api',
});

export class DefaultSLOApi implements SLOApi {
  private readonly promApi: PrometheusApi;

  constructor(options: { promApi: PrometheusApi }) {
    this.promApi = options.promApi;
  }

  async listSLOs(filter: SLOFilter = {}): Promise<SLO[]> {
    const {
      team = '.*',
      service = '.*',
      app = '.*',
      kind = '.*',
      name = '.*',
    } = filter;

    const queryFilter = `{team=~"${team}", service=~"${service}", app=~"${app}", kind=~"${kind}", slo=~"${name}"}`;

    const queries = Object.values(sloQueries).sort((a, b) =>
      a.localeCompare(b),
    );
    const results = await Promise.all(
      queries.map(query => this.promApi.instantQuery(`${query}${queryFilter}`)),
    );

    const slos: Record<string, SLO> = {};

    // We iterate over each query
    results.forEach((queryResult, index) => {
      // We iterate over each SLO in a query
      queryResult.result.forEach(serie => {
        const sloName = serie.metric.labels.slo;
        // First query initializes the SLO
        if (!slos[sloName]) {
          slos[sloName] = {
            errored: false,
            metadata: {
              name: sloName,
              app: serie.metric.labels.app,
              service: serie.metric.labels.service,
              team: serie.metric.labels.team,
            },
            spec: {
              kind: serie.metric.labels.kind,
              window: '0d',
              objective: 0,
            },
            status: {
              errors: 0,
              total: 0,
              availability: 0,
              budget: 0,
            },
          };
        }

        // status and spec data is obtained from different queries
        switch (queries[index]) {
          case sloQueries.objective:
            slos[sloName].spec.objective = serie.value.value;
            break;
          case sloQueries.availability: {
            const availability = serie.value.value;
            // If availability is 0, there is a problem with the SLO definition
            if (availability === 0) {
              slos[sloName].errored = true;
            }
            slos[sloName].status.availability = availability;
            break;
          }
          case sloQueries.window:
            slos[sloName].spec.window = secondsToDays(serie.value.value);
            break;
          case sloQueries.errors_total:
            slos[sloName].status.errors = Number(serie.value.value);
            break;
          case sloQueries.requests_total:
            slos[sloName].status.total = Number(serie.value.value);
            break;
          default:
            break;
        }
      });
    });

    // Calculate error budget left
    Object.values(slos).forEach(slo => {
      slo.status.budget = calculateErrorBudget(
        slo.spec.objective,
        slo.status.availability,
      );
    });

    return Object.values(slos).filter(slo => {
      if (!filter.onlyAtRisk) {
        return true;
      }
      return slo.status.budget < budgetAtRiskPercent;
    });
  }
}
