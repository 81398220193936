import React from 'react';
import { Grid } from '@material-ui/core';
import { Page, Content, ContentHeader } from '@backstage/core-components';
import { VitalsUptimeCard } from './VitalsUptimeCard';

export const VitalsPage = () => {
  return (
    <Page themeId="home">
      <Content>
        <ContentHeader title="Performance" />
        <Grid container direction="column">
          <Grid item>
            <VitalsUptimeCard />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
