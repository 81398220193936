import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { BackstagePaletteAdditions } from '@backstage/theme';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: 'auto',
    height: 35,
  },
  container: {
    display: 'flex',
  },
  title: {
    lineHeight: '1.5',
    color: (theme.palette as unknown as BackstagePaletteAdditions)?.navigation
      ?.color,
  },
}));

const LogoFull = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <img
        className={classes.logo}
        src="/EFG_Logo_Black_RGB.svg"
        alt="EFG Logo"
      />
      <Box ml={1}>
        <Typography variant="h5" className={`${classes.title}`}>
          BACKSTAGE
        </Typography>
      </Box>
    </Grid>
  );
};

export default LogoFull;
