import React from 'react';
import { Grid } from '@material-ui/core';
import { AlertsTableWrapper } from '@efg/backstage-plugin-prometheus';
import { EntitySLOTable } from '@efg/backstage-plugin-slo';

export const ReliabilityPage = () => {
  return (
    <Grid container direction="column">
      <Grid item xs={12} md={12}>
        <EntitySLOTable />
      </Grid>
      <Grid item xs={12} md={12}>
        <AlertsTableWrapper />
      </Grid>
    </Grid>
  );
};
