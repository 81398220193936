import React from 'react';
import {
  Content,
  ContentHeader,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import {
  CatalogFilterLayout,
  EntityListProvider,
  EntityTagPicker,
  UserListPicker,
  UserListFilterKind,
  EntityListPagination,
  EntitySearchBar,
} from '@backstage/plugin-catalog-react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { rootRoadmapEditorRouteRef } from '../../routes';
import { RoadmapEntityPicker } from './RoadmapEntityFilter';
import { CreateRoadmapButton } from './CreateRoadmapButton';
import { RoadmapEntityList } from './RoadmapCardGrid';

/**
 * Props for {@link RoadmapHomePage}
 *
 * @public
 */
export type RoadmapHomePagePageProps = {
  initialFilter?: UserListFilterKind;
  pagination?: EntityListPagination;
};

/**
 * A Page that lists all the registered RoadMap and Golden Paths
 *
 * @public
 */
export const RoadmapHomePage = (props: RoadmapHomePagePageProps) => {
  const { initialFilter, pagination } = props;
  const roadmapEditorRoute = useRouteRef(rootRoadmapEditorRouteRef);

  return (
    <Page themeId="home">
      <Header
        title="Techdoc Roadmap and Golden Path"
        subtitle="Interactive Visual documentations"
      />

      <Content>
        <ContentHeader>
          <CreateRoadmapButton
            title="Create New Roadmap or Golden Path"
            to={roadmapEditorRoute && roadmapEditorRoute()}
          />
          <SupportButton>
            Explore or Create documentation in a Visual and Interactive format.
          </SupportButton>
        </ContentHeader>
        <EntityListProvider pagination={pagination}>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntitySearchBar />
              <RoadmapEntityPicker />
              <UserListPicker initialFilter={initialFilter} />
              <EntityTagPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <RoadmapEntityList />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </Page>
  );
};
