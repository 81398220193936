import { CompoundEntityRef } from '@backstage/catalog-model';
import useAsyncRetry from 'react-use/lib/useAsyncRetry';
import { AsyncState } from 'react-use/lib/useAsync';
import { techdocsStorageApiRef } from '@backstage/plugin-techdocs-react';
import { useApi } from '@backstage/core-plugin-api';
import {
  roadmapStorageApiRef,
  Roadmap,
  DocsMetadata,
} from '../api/RoadmapStorageApi';

export type RawPage = {
  content: string;
  path: string;
  entityId: CompoundEntityRef;
};

export type RawRoadmap = {
  content: Roadmap;
  path: string;
  entityId: CompoundEntityRef;
};

export type RawDocMetadata = {
  content: DocsMetadata;
  entityId: CompoundEntityRef;
};

// Fetch A Raw Documentation Page
export function useRawPage(
  path: string,
  kind: string,
  namespace: string,
  name: string,
): AsyncState<RawPage> & {
  retry(): void;
} {
  const techdocsStorageApi = useApi(techdocsStorageApiRef);

  return useAsyncRetry(async () => {
    const content = await techdocsStorageApi.getEntityDocs(
      { kind, namespace, name },
      path,
    );

    return {
      content,
      path,
      entityId: {
        kind,
        name,
        namespace,
      },
    };
  }, [techdocsStorageApi, kind, namespace, name, path]);
}

// Request to fetch the Roadmap JSON definition
// Type conversion is very inning so this hooks serves for JSON asserts
export function useRawRoadmap(
  path: string,
  entity: CompoundEntityRef,
): AsyncState<RawRoadmap> & {
  retry(): void;
} {
  const roadmapStorageApi = useApi(roadmapStorageApiRef);

  return useAsyncRetry(async () => {
    const content = await roadmapStorageApi.getRoadmap(entity, path);

    return {
      content,
      path,
      entityId: entity,
    };

    // DependencyList for useAsyncRetry need to be value and not object otherwise it keeps loading
  }, [roadmapStorageApiRef, entity.kind, entity.name, entity.namespace, path]);
}

export function useRawDocMetadata(
  entity: CompoundEntityRef,
): AsyncState<RawDocMetadata> & {
  retry(): void;
} {
  const roadmapStorageApi = useApi(roadmapStorageApiRef);

  return useAsyncRetry(async () => {
    const content = await roadmapStorageApi.getDocsMetadata(entity);

    return {
      content,
      entityId: entity,
    };

    // DependencyList for useAsyncRetry need to be value and not object otherwise it keeps loading
  }, [roadmapStorageApiRef, entity.kind, entity.name, entity.namespace]);
}
