import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Link from '@mui/material/Link';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { ReactNode } from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type HeadingProps = {
  children: ReactNode;
};
const Heading: React.FC<HeadingProps> = ({ children }) => (
  <Typography
    variant="inherit"
    sx={{ fontWeight: 'bold', mb: 0.5, fontSize: '1.1rem' }}
  >
    {children}
  </Typography>
);

const ReliabilityInfoDialog = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        size="small"
        startIcon={<InfoIcon fontSize="small" />}
        fullWidth
      >
        What does this page show?
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Reliability Tab Info</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We have a set of metrics which we check in order to understand the
            level of reliability of the services
            <Box sx={{ mt: 2 }} />
            <Heading>Incident follow-ups</Heading>
            According to our{' '}
            <Link
              href="https://backstage.efg-tech.gg/docs/default/domain/incident-management/5.%20Post%20Incident/3.policies/"
              target="_blank"
              rel="noopener"
            >
              policy
            </Link>{' '}
            follow-ups from incidents which have been raised during the
            post-incident flow should be resolved in a reasonable amount of
            time.
            <Box sx={{ mt: 2 }} />
            It’s a good practice to have the follow-ups exported as a JIRA
            ticket.
            <Box sx={{ mt: 3 }} />
            <Heading>SLOs and error budget</Heading>
            Periodically checking SLO error budgets allows us to understand if
            our applications are performing as expected, or if we need to
            prioritise work in specific areas because we are at risk of running
            out of error budget.
            <Box sx={{ mt: 2 }} />
            Detailed SLO{' '}
            <Link
              href="https://backstage.efg-tech.gg/docs/default/domain/slo/"
              target="_blank"
              rel="noopener"
            >
              docs
            </Link>{' '}
            can be found on backstage
            <Box sx={{ mt: 3 }} />
            <Heading>Uptime/Downtime</Heading>
            Uptime percentage or amount of downtime for owned services in the
            last 30 days.
            <Box sx={{ mt: 2 }} />
            More information about how the uptime is calculated can be found{' '}
            <Link
              href="https://backstage.efg-tech.gg/docs/default/domain/incident-management/6.%20Uptime/1.%20uptime/#uptime"
              target="_blank"
              rel="noopener"
            >
              here
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export { ReliabilityInfoDialog, Heading };
