import React from 'react';
import {
  Page,
  Content,
  ContentHeader,
  ItemCardGrid,
  SupportButton,
} from '@backstage/core-components';
import { LeaderboardRankingTable } from '@efg/backstage-plugin-leaderboard';
import { CortexInsightCard } from '@efg/cortex-backstage-plugin';

export const LeaderboardPage = () => (
  <Page themeId="tool">
    <Content>
      <ContentHeader title="Technology leaderboard">
        <SupportButton
          items={[
            {
              title: 'Documentation',
              links: [
                {
                  title: 'Definitions and FAQ',
                  url: 'https://backstage.efg-tech.gg/docs/default/component/backstage/plugins/leaderboard/',
                },
              ],
            },
          ]}
        >
          LEARN MORE
        </SupportButton>
      </ContentHeader>
      <ItemCardGrid>
        <CortexInsightCard />
      </ItemCardGrid>
      <LeaderboardRankingTable />
    </Content>
  </Page>
);
