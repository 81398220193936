import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';
import { PrometheusApiRef } from '@efg/backstage-plugin-prometheus';
import { rootRouteRef } from './routes';
import { DefaultSLOApi, SLOApiRef } from './api';

export const sloApiFactory = createApiFactory({
  api: SLOApiRef,
  deps: { promApi: PrometheusApiRef },
  factory: ({ promApi }) => {
    return new DefaultSLOApi({
      promApi,
    });
  },
});

export const sloPlugin = createPlugin({
  apis: [sloApiFactory],
  id: 'slo',
  routes: {
    root: rootRouteRef,
  },
});

export const SLOTable = sloPlugin.provide(
  createComponentExtension({
    name: 'SLOTable',
    component: {
      lazy: () => import('./components/SLOTable').then(m => m.SLOTable),
    },
  }),
);

export const EntitySLOTable = sloPlugin.provide(
  createComponentExtension({
    name: 'EntitySLOTable',
    component: {
      lazy: () =>
        import('./components/EntitySLOTable').then(m => m.EntitySLOTable),
    },
  }),
);

export const SLOTableHome = sloPlugin.provide(
  createComponentExtension({
    name: 'SLOTableHome',
    component: {
      lazy: () => import('./components/SLOTableHome').then(m => m.SLOTableHome),
    },
  }),
);
