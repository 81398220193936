import { Entity } from '@backstage/catalog-model';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { KUBECOST_TEAM_COLLECTION_ANNOTATION } from '../annotations';
import { CostMetricsResults, KubecostResponse } from './model';

export interface CostMetricsApi {
  getEntityMetrics(
    entity: GetEntityMetricsRequest,
  ): Promise<CostMetricsResults>;
}

export const costMetricsApiRef = createApiRef<CostMetricsApi>({
  id: 'cost-metrics.api',
});

export interface TimeRange {
  start: string;
  end: string;
}

export interface GetEntityMetricsRequest {
  entity: Entity;
}

export class KubeCostMetricsApi implements CostMetricsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly proxyId: string;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
    this.proxyId = `kubecost`;
  }

  /**
   * Handles errors when talking to the backend plugin
   */
  private async handleResponse(response: Response): Promise<KubecostResponse> {
    if (!response.ok) {
      const payload = await response.text();
      let message;
      switch (response.status) {
        case 404:
          message = `Could not find the KubeCost Backend (HTTP 404). Make sure the plugin has been fully installed.`;
          break;
        default:
          message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      }
      throw new Error(message);
    }

    return await response.json();
  }

  async getEntityMetrics(
    request: GetEntityMetricsRequest,
  ): Promise<CostMetricsResults> {
    if (
      !request.entity.metadata?.annotations?.[
        KUBECOST_TEAM_COLLECTION_ANNOTATION
      ]
    ) {
      throw new Error(
        `Missing entity annotation ${KUBECOST_TEAM_COLLECTION_ANNOTATION}`,
      );
    }

    const collectionId =
      request.entity.metadata?.annotations?.[
        KUBECOST_TEAM_COLLECTION_ANNOTATION
      ];
    const proxyUrl = `${await this.discoveryApi.getBaseUrl('proxy')}/${this.proxyId}`;
    const response = await this.fetchApi.fetch(
      `${proxyUrl}/model/collection/query/total?id=${collectionId}&window=30d`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return await this.handleResponse(response).then(
      (resp): CostMetricsResults => {
        return {
          provider: {
            name: 'Kubecost',
            dashboardURL: `https://kubecost.efg-tech.gg/collections:${collectionId}:groups`,
          },
          results: [
            {
              TimeRange: {
                start: resp.data.window.start,
                end: resp.data.window.end,
              },
              Metrics: {
                collectionTotal: resp.data.data.cost.totalCost,
              },
            },
          ],
        };
      },
    );
  }
}
