import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { makeStyles, createStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { NodeData } from '../RoadmapEditorComponent/store';

const useStyles = makeStyles(() =>
  createStyles({
    primaryNode: {
      color: 'black',
      minHeight: '40px',
      minWidth: '150px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderWidth: '2px',
      fontWeight: 'bold',
      backgroundColor: 'gold',
      border: '2px solid black',
      padding: '2px',
      textAlign: 'center',
    },
  }),
);

const PrimaryNode = ({
  data,
  isConnectable,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}: NodeProps<NodeData>) => {
  const classes = useStyles();

  return (
    <div className={classes.primaryNode}>
      <Handle
        type="target"
        position={targetPosition}
        isConnectable={isConnectable}
      />
      <Link to={data.docPath ? data.docPath : ''}>{data.label}</Link>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Left}
        id="lt"
        style={{ top: 10, background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Left}
        id="lb"
        style={{ bottom: 5, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="rt"
        style={{ top: 10, background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="rb"
        style={{ bottom: 5, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </div>
  );
};

PrimaryNode.displayName = 'PrimaryNode';

export default memo(PrimaryNode);
